import { useState } from 'react'
import { PageOrder, SearchCasesStateItem } from 'bff/moons/generated/case-manager'
import dayjs from 'dayjs'
import { bff } from './bff-hooks'

export const oddListTabItems = [
    {
        key: 'new',
        tab: 'Todo',
    },
    {
        key: 'inProgress',
        tab: 'In Progress',
    },
    {
        key: 'waitingForCustomer',
        tab: 'Waiting for Customer',
    },
    {
        key: 'offboarding',
        tab: 'Offboarding',
    },
    {
        key: 'oddCompleted',
        tab: 'ODD Completed',
    },
]

export const DEFAULT_PAGE_SIZE = 10

export const tabNameToCasesStateMapper: { [key: string]: SearchCasesStateItem[] } = {
    new: [SearchCasesStateItem.NEW, SearchCasesStateItem.INFORMATION_RECEIVED],
    inProgress: [SearchCasesStateItem.IN_PROGRESS],
    waitingForCustomer: [SearchCasesStateItem.WAITING_FOR_CUSTOMER],
    offboarding: [
        SearchCasesStateItem.OFFBOARDING_INITIATED,
        SearchCasesStateItem.OFFBOARDING_COMPLETED,
        SearchCasesStateItem.WALLET_BLOCKED,
    ],
    oddCompleted: [SearchCasesStateItem.ODD_COMPLETED],
}

export const mapDueDateToSearchParams = (dueDate: string | undefined) => {
    if (dueDate === 'this-week') {
        return {
            due_from: dayjs().toISOString(),
            due_to: dayjs().add(7, 'days').toISOString(),
        }
    } else if (dueDate === 'overdue') {
        return {
            due_from: undefined,
            due_to: dayjs().toISOString(),
        }
    } else {
        return {
            due_from: undefined,
            due_to: undefined,
        }
    }
}
export const mapStateToTab = (state: SearchCasesStateItem[] | undefined) => {
    if (!state) return 'new'

    const stateKey = Object.keys(tabNameToCasesStateMapper).find((key) =>
        tabNameToCasesStateMapper[key].includes(state[0])
    )

    return stateKey || 'new'
}

const getSorting = (state: string[] | undefined) => {
    if (state?.includes('INFORMATION_RECEIVED')) {
        return { sorting_keys: ['state'], sorting_order: [PageOrder.ASC] }
    }
    return {
        sorting_keys: undefined,
        sorting_order: undefined,
    }
}

export interface FilterParams {
    country?: string
    state?: SearchCasesStateItem[]
    dueDate?: string
    assigneeId?: string
    entityId?: string
}

export const useFilters = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const countryUrlParam = urlSearchParams.get('country') || undefined
    const stateUrlParam = urlSearchParams.get('state') || tabNameToCasesStateMapper['new'].join(',')
    const dueDateUrlParam = urlSearchParams.get('dueDate') || undefined
    const assigneeIdUrlParam = urlSearchParams.get('assigneeId') || undefined
    const entityIdUrlParam = urlSearchParams.get('entityId') || undefined

    const [activeTab, setActiveTab] = useState(
        mapStateToTab((stateUrlParam?.split(',') as SearchCasesStateItem[]) || 'new')
    )

    const [searchParams, setSearchParams] = useState({
        metadata: countryUrlParam
            ? JSON.stringify({ companyCountryCode: countryUrlParam })
            : undefined,
        assigneeId: assigneeIdUrlParam,
        entityId: entityIdUrlParam,
        state: stateUrlParam?.split(',') as SearchCasesStateItem[],
        ...mapDueDateToSearchParams(dueDateUrlParam),
        ...getSorting(stateUrlParam.split(',')),
    })

    const { data, isFetching } = bff.oddListPage.searchOddList.useQuery(
        {
            ...searchParams,
            include_deleted: true,
            offset: undefined,
            limit: DEFAULT_PAGE_SIZE,
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const updateSearchParams = (params: Record<string, any>) =>
        setSearchParams((prev) => ({ ...prev, ...params }))

    const updateUrl = (params: FilterParams) => {
        const currentParams = {
            country: countryUrlParam,
            state: stateUrlParam?.split(',') as SearchCasesStateItem[],
            dueDate: dueDateUrlParam,
            assigneeId: assigneeIdUrlParam,
            entityId: entityIdUrlParam,
        }

        const newParams = {
            ...currentParams,
            ...params,
        }

        const newSearchParams = new URLSearchParams()

        if (newParams?.country) newSearchParams.set('country', newParams?.country)
        if (newParams?.state) {
            const newParamsState = newParams?.state as string[] | undefined
            newSearchParams.set(
                'state',
                newParamsState && newParamsState.length > 0 && Array.isArray(newParamsState)
                    ? newParamsState.join(',')
                    : ''
            )
        }
        if (newParams?.dueDate) newSearchParams.set('dueDate', newParams?.dueDate)
        if (newParams?.assigneeId) newSearchParams.set('assigneeId', newParams?.assigneeId)
        if (newParams?.entityId) newSearchParams.set('entityId', newParams?.entityId)
        window.history.pushState({}, '', `?${newSearchParams.toString()}`)
    }

    const clearParams = () => {
        const clearSearchParams = new URLSearchParams()

        clearSearchParams.delete('country')
        clearSearchParams.delete('dueDate')
        clearSearchParams.delete('assigneeId')
        clearSearchParams.delete('entityId')

        clearSearchParams.set('state', tabNameToCasesStateMapper[activeTab].join(','))

        window.history.pushState({}, '', `?${clearSearchParams.toString()}`)

        updateSearchParams({
            metadata: undefined,
            state: tabNameToCasesStateMapper[activeTab],
            assigneeId: undefined,
            entityId: undefined,
            offset: undefined,
            limit: DEFAULT_PAGE_SIZE,
            ...mapDueDateToSearchParams(undefined),
        })
    }

    const updateActiveTab = (key: string) => {
        const statuses = tabNameToCasesStateMapper[key].map((status) => status)
        updateUrl({ state: statuses })
        updateSearchParams({ state: statuses, offset: undefined, limit: DEFAULT_PAGE_SIZE })
        setActiveTab(key)
    }

    return {
        filters: {
            country: countryUrlParam,
            state: stateUrlParam?.split(',') as SearchCasesStateItem[],
            dueDate: dueDateUrlParam,
            assigneeId: assigneeIdUrlParam,
            entityId: entityIdUrlParam,
        },
        data,
        isFetching,
        activeTab,
        updateSearchParams,
        updateUrl,
        clearParams,
        updateActiveTab,
    }
}
